import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { useBlogAuthors } from '@helpers-blog'


  function PagePrivacy() {
    return (
      <main>
        <h1>Privacy Policy</h1>
        <body>
          <p>

          Privacy Policy 
  
Protecting your private information is our priority. This Statement of Privacy applies to https://MD.marketing, and MD.marketing and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to MD.marketing include https://MD.marketing and MD.marketing. The MD.marketing website is a digital marketing blog site. By using the MD.marketing website, you consent to the data practices described in this statement. 
  
Collection of your Personal Information 
MD.marketing may collect anonymous demographic information, which is not unique to you, such as your: 
  
 -	Age 
 -	Gender 
  
We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. 
  
Sharing Information with Third Parties 
MD.marketing does not sell, rent or lease its customer lists to third parties. 
  
MD.marketing may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third party. MD.marketing may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to MD.marketing, and they are required to maintain the confidentiality of your information. 
  
MD.marketing may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on MD.marketing or the site; (b) protect and defend the rights or property of MD.marketing; and/or (c) act under exigent circumstances to protect the personal safety of users of MD.marketing, or the public. 
  
Opt-Out of Disclosure of Personal Information to Third Parties 
In connection with any personal information we may disclose to a third party for a business purpose, you have the right to know: 
•	The categories of personal information that we disclosed about you for a business purpose. 
  
You have the right under the California Consumer Privacy Act of 2018 (CCPA) and certain other privacy and data protection laws, as applicable, to opt-out of the disclosure of your personal information. If you exercise your right to opt-out of the disclosure of your personal information, we will refrain from disclosing your personal information, unless you subsequently provide express authorization for the disclosure of your personal information. To opt-out of the disclosure of your personal information, visit this Web page _________________. 
  
Tracking User Behavior 
MD.marketing may keep track of the websites and pages our users visit within MD.marketing, in order to determine what MD.marketing services are the most popular. This data is used to deliver customized content and advertising within MD.marketing to customers whose behavior indicates that they are interested in a particular subject area. 
  
Automatically Collected Information 
Information about your computer hardware and software may be automatically collected by MD.marketing. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the MD.marketing website. 
  
Right to Deletion 
Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: 
•	Delete your personal information from our records; and 
•	Direct any service providers to delete your personal information from their records. 
  
Please note that we may not be able to comply with requests to delete your personal information if it is necessary to: 
•	Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; 
•	Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; 
•	Debug to identify and repair errors that impair existing intended functionality; 
•	Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; 
•	Comply with the California Electronic Communications Privacy Act; 
•	Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent; 
•	Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; 
•	Comply with an existing legal obligation; or 
•	Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. 
  
Children Under Thirteen 
MD.marketing does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. 
  
Opt-Out & Unsubscribe from Third Party Communications 
We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from third-party partners of MD.marketing by contacting us here: 
- Web page: _________________ 
- Email: _________________ 
- Phone: _________________ 
  
Changes to this Statement 
MD.marketing reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy. 
  
Contact Information 
MD.marketing welcomes your questions or comments regarding this Statement of Privacy. If you believe that MD.marketing has not adhered to this Statement, please contact MD.marketing at: 
  
MD.marketing 
_________________ 
_________________, _________________ _________________ 
  
Email Address: 
_________________ 
  
Telephone number: 
_________________ 
  
Effective as of February 18, 2023 
  


          </p>
        </body>

      </main>
    )
  }

export default PagePrivacy
